import React from 'react'
import classNames from 'classnames'

import './styles.css'

const InputText = ({
  onChange,
  placeholder,
  type = 'text',
  min,
  max,
  name,
  filterStyle,
  required,
  value
}) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      className={classNames({
        'ba b--moon-gray ph4 pv1 w-100 input-focus josefin': true,
        'br2 h42 mb3 f11': !filterStyle,
        'br4 h62': filterStyle
      })}
      min={min}
      max={max}
      name={name}
      value={value}
      required={required}
    />
  )
}

export default InputText
