import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '@components/layout'
import Title from '@components/title'
import ApplyForm from '@components/apply-form'
import house from '../assets/house.jpg'

const ApplyPage = ({ uri }) => {
  return (
    <Layout currentLocation={uri}>
      <Helmet>
        <title>Vuokrahakemus | Tuulia Kodit</title>
      </Helmet>
      <section className="section section--bg pb7-sm pb9-m pb10-l">
        <div className="container flex flex-wrap mb6-sm mb8">
          <div className="w-100-sm w-50 pr0-sm pr5 mb5-sm">
            <Title as="h1" align="left" className="mb4" noUnderline>
              Vuokrahakemus
            </Title>
            <Title heading="h6" className="gray-blue mb5">
              Tee hakemus tai kysy lisätietoja asunnoistamme.
            </Title>

            <ApplyForm width="458px" />
          </div>

          <div className="w-100-sm w-50">
            <img src={house} alt="" className="center" />
          </div>
        </div>

        <div className="container flex flex-wrap nb5-sm">
          <ContactItem title="Postiosoite">
            <p>
              Laaksokatu 2,
              <br /> 18100 Heinola
            </p>
          </ContactItem>

          <ContactItem title="Puhelinnumero">
            <a href="tel:0341089510" className="db">
              03-41089510
            </a>
          </ContactItem>

          <ContactItem title="Sähköposti">
            <a
              href="mailto:vuokrakodit@tuuliacapital.fi"
              className="db break-word"
            >
              vuokrakodit@tuuliacapital.fi
            </a>
          </ContactItem>

          <ContactItem title="Aukioloajat">
            <p>Ma-Pe: 8-16</p>
          </ContactItem>
        </div>
      </section>
    </Layout>
  )
}

export default ApplyPage

const ContactItem = ({ title, children }) => (
  <div className="flex-grow-1 w-50-sm mb5-sm pr4-sm">
    <Title heading="h6" noUnderline className="mb3-sm mb4">
      {title}
    </Title>
    {children}
  </div>
)
