import React, { useState } from 'react'
import { navigate } from 'gatsby'

import Button from '@components/button'
import InputText from '@components/input-text'
import InputTextarea from '@components/input-textarea'
import Checkbox from '@components/checkbox'
import Title from '@components/title'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ApplyForm = ({ width, className }) => {
  const [formFields, setFormFields] = useState({})

  const handleSubmit = e => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': e.target.getAttribute('name'),
        ...formFields
      })
    })
      .then(() => navigate('/kiitos'))
      .catch(error => alert(error))

    e.preventDefault()
  }

  const handleChange = e =>
    setFormFields({ ...formFields, ...{ [e.target.name]: e.target.value } })

  const renderForm = (
    <form
      className="mw6"
      onSubmit={handleSubmit}
      data-netlify="true"
      name="Vuokrahakemus"
      method="post"
      netlify-honeypot="bot-field"
    >
      <FormGroup>
        <InputTextarea
          placeholder="Haettava kohde"
          name="kohde"
          onChange={handleChange}
        />
      </FormGroup>

      <FormGroup>
        <FormTitle text="Hakijan tiedot" />
        <InputText
          type="name"
          name="Nimi"
          placeholder="Nimi"
          onChange={handleChange}
        />
        <InputText
          name="Puhelinnumero"
          placeholder="Puhelinnumero"
          onChange={handleChange}
        />
        <InputText
          type="email"
          name="Email"
          placeholder="E-mail"
          onChange={handleChange}
        />
        <InputTextarea
          placeholder="Osoite"
          name="Osoite"
          onChange={handleChange}
        />
        <InputText
          name="ammatti"
          placeholder="Ammatti"
          onChange={handleChange}
        />
        <InputText
          name="työnantaja"
          placeholder="Työnantaja / Opiskelupaikka"
          onChange={handleChange}
        />
        <InputText
          name="Kuukausitulot ennen veroja"
          placeholder="Kuukausitulot ennen veroja"
          onChange={handleChange}
        />
      </FormGroup>

      <FormGroup>
        <FormTitle text="Puoliso/toinen hakija" />
        <InputText
          name="Puolison nimi"
          placeholder="Nimi"
          onChange={handleChange}
        />
        <InputText
          name="Puolison puhelinnumero"
          placeholder="Puhelinnumero"
          onChange={handleChange}
        />
        <InputText
          type="email"
          name="Puolison email"
          placeholder="E-mail"
          onChange={handleChange}
        />
        <InputTextarea
          placeholder="Osoite"
          name="Puolison osoite"
          onChange={handleChange}
        />
        <InputText
          name="Puolison ammatti"
          placeholder="Ammatti"
          onChange={handleChange}
        />
        <InputText
          name="Puolison työnantaja"
          placeholder="Työnantaja / Opiskelupaikka"
          onChange={handleChange}
        />
        <InputText
          name="Puolison kuukausitulot"
          placeholder="Kuukausitulot ennen veroja"
          onChange={handleChange}
        />
      </FormGroup>

      <FormGroup>
        <FormTitle text="Muut taustatiedot" />
        <RadioTitle text="Nykyinen asumismuoto" />
        {/* add on change */}
        <Checkbox
          label="Vuokra-asunto"
          name="Asumismuoto"
          value="vuokra"
          onChange={handleChange}
          isRadio
        />
        <Checkbox
          label="Omistusasunto"
          name="Asumismuoto"
          value="omistus"
          onChange={handleChange}
          isRadio
        />
        <Checkbox
          label="Työsuhdeasunto"
          name="Asumismuoto"
          value="työ"
          onChange={handleChange}
          isRadio
        />
        <Checkbox
          label="Vanhempien tai lähipiirin luona"
          name="Asumismuoto"
          value="vanhemmat"
          onChange={handleChange}
          isRadio
        />
        <Checkbox
          label="Muu vaihtoehto"
          name="Asumismuoto"
          value="muu"
          onChange={handleChange}
          isRadio
        />

        <RadioTitle text="Muuton syy" />
        <Checkbox
          label="Opiskelupaikka"
          name="Muuton syy"
          value="opiskelu"
          onChange={handleChange}
          isRadio
        />
        <Checkbox
          label="Työpaikka"
          name="Muuton syy"
          value="työpaikka"
          onChange={handleChange}
          isRadio
        />
        <Checkbox
          label="Henkilökohtaiset ja sosiaaliset syyt"
          name="Muuton syy"
          value="henkilökohtaiset syyt"
          onChange={handleChange}
          isRadio
        />
        <Checkbox
          label="Vuokrasuhteen irtisanominen"
          name="Muuton syy"
          value="vuokrasuhteen irtisanominen"
          onChange={handleChange}
          isRadio
        />
        <Checkbox
          label="Muu syy"
          name="Muuton syy"
          value="muu syy"
          onChange={handleChange}
          isRadio
        />
      </FormGroup>

      <FormTitle text="Onko luottotiedoissasi maksuhäiriöitä?" />
      <RadioTitle text="Tarkistamme hakijoiden luottotiedot ennen vuokrasopimuksen solmimista." />
      <Checkbox
        label="Kyllä"
        name="Häiriömerkintä"
        value="kyllä"
        onChange={handleChange}
        isRadio
      />
      <Checkbox
        label="Ei"
        name="Häiriömerkintä"
        value="ei"
        onChange={handleChange}
        isRadio
      />

      <RadioTitle text="Kanssani muuttaa lemmikkejä" />
      <Checkbox
        label="Kyllä"
        name="Lemmikki"
        value="kyllä"
        onChange={handleChange}
        isRadio
      />
      <Checkbox
        label="Ei"
        name="Lemmikki"
        value="ei"
        onChange={handleChange}
        isRadio
      />

      <RadioTitle text="Hakemuksen jättäjä ja/tai kanssahakija tupakoi" />
      <Checkbox
        label="Kyllä"
        name="Tupakointi"
        value="kyllä"
        onChange={handleChange}
        isRadio
      />
      <Checkbox
        label="Ei"
        name="Tupakointi"
        value="ei"
        onChange={handleChange}
        isRadio
      />

      <InputText
        name="Asunnon tarve alkaen"
        placeholder="Asunnon tarve alkaen"
        onChange={handleChange}
      />

      <InputTextarea
        placeholder="Lisätietoja"
        name="Lisätietoja"
        onChange={handleChange}
      />

      <Button type="submit" className="w-100" size="md">
        Lähetä
      </Button>
    </form>
  )

  return (
    <div style={{ maxWidth: width }} className={className}>
      {renderForm}
    </div>
  )
}

export default ApplyForm

const FormTitle = ({ text }) => (
  <Title heading="h5" className="mb3" color="dark-gray" noUnderline>
    {text}
  </Title>
)

const RadioTitle = ({ text }) => (
  <Title heading="h6" noUnderline className="mb3-sm mb4" fontWeight="normal">
    {text}
  </Title>
)

const FormGroup = ({ children }) => <div className="mb5">{children}</div>
